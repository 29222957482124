<template>
  <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenuVendor></sideMenuVendor>
              </div>
              <div class="col-lg-9">
                <div>
                  <h4 class="comm-title">Pending Auctions</h4>
                </div>
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else>
                  <!-- TODO negotiation vedor alert currently disabled need to give notification icon  -->
                  <div
                    v-if="negotiationDetails.NegBycustId == 2451.0133"
                    class="pending-alert"
                  >
                    <Alert
                      :alertContent="'Negotiation was initated by customer'"
                      :detailsList="negotiationDetails"
                    ></Alert>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-4"
                      v-for="VehicleDetails in items"
                      :key="VehicleDetails.vehId"
                      style="margin-bottom: 15px; padding: 0px"
                    >
                      <div>
                        <div class="">
                          <!--new-main-tile-->
                          <section>
                            <div class="" style="margin: 0px 6px">
                              <div class="">
                                <div class="carTileWrap">
                                  <div
                                    class="cwrapImage"
                                    style="position: relative"
                                  >
                                    <router-link
                                      :to="{
                                        name: 'VehicleDeailsPending',
                                        params: {
                                          url_key: VehicleDetails.vehId,
                                        },
                                      }"
                                    >
                                      <img
                                        :src="path + VehicleDetails.vehImage1"
                                        class="img-fluid img-responsive border5px"
                                      />
                                    </router-link>

                                    <div class="topleft">
                                      <!-- <div>
                                        <CountDown
                                          :customstyle="'background-color: beige;'"
                                          :deadline="VehicleDetails.aucExtDate"
                                        ></CountDown>
                                      </div> -->
                                    </div>
                                    <div class="topright">
                                      <div style="display: flex"></div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="vehicleName">
                                      <router-link
                                        :to="{
                                          name: 'VehicleDeailsPending',
                                          params: {
                                            url_key: VehicleDetails.vehId,
                                          },
                                        }"
                                      >
                                        <h5>
                                          {{ VehicleDetails.brandName }}
                                          {{ VehicleDetails.modelName }}
                                          {{ VehicleDetails.manYear }}
                                        </h5>
                                      </router-link>
                                    </div>
                                    <div>
                                      <hr style="margin: 0px" />

                                      <div class="flex-bttns-p">
                                        <div
                                          v-if="
                                            VehicleDetails.negotiationStatus ===
                                              null &&
                                            VehicleDetails.isFirstCustomer === 1
                                          "
                                        >
                                          <button
                                            class="tile-bid-now"
                                            tabindex="-1"
                                            @click="
                                              ShowAlert(
                                                VehicleDetails,
                                                'Closing'
                                              )
                                            "
                                            style="background-color: #e76e6e"
                                          >
                                            Send to Close
                                          </button>
                                        </div>

                                        <div
                                          class=""
                                          v-if="
                                            VehicleDetails.LatestBidAmount <
                                              VehicleDetails.aucMinWinAmount &&
                                            VehicleDetails.negotiationStatus ==
                                              true
                                          "
                                        >
                                          <div class="text-center">
                                            <button
                                              class="custom-btn btn-11"
                                              tabindex="-1"
                                              style="
                                                background-color: #648ed1ad !important;
                                              "
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              <span
                                                ><i
                                                  class="fad fa-handshake"
                                                ></i>
                                                Negotiating
                                              </span>
                                            </button>
                                          </div>
                                        </div>

                                        <div
                                          class=""
                                          v-if="
                                            VehicleDetails.LatestBidAmount <
                                              VehicleDetails.aucMinWinAmount &&
                                            VehicleDetails.negotiationStatus !=
                                              true 
                                          "
                                        >
                                          <div class="text-center">
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              <span>Negotiate </span>
                                            </button>
                                            <!-- <button @click="showAlert">Hello world</button> -->
                                          </div>
                                        </div>

                                        <div
                                          v-if="
                                            VehicleDetails.negotiationStatus ===
                                              null &&
                                            VehicleDetails.isFirstCustomer === 1
                                          "
                                        >
                                          <button
                                            class="tile-bid-now"
                                            tabindex="-1"
                                            @click="
                                              ShowAlert(
                                                VehicleDetails,
                                                'Sale Confirmed'
                                              )
                                            "
                                            style="background-color: #75c275"
                                          >
                                            Confirm Sale
                                          </button>
                                        </div>

                                        <div
                                          class=""
                                          v-if="
                                            VehicleDetails.LatestBidAmount <
                                              VehicleDetails.aucMinWinAmount &&
                                            VehicleDetails.negotiationStatus ===
                                              null &&
                                            VehicleDetails.isFirstCustomer === 1
                                          "
                                        >
                                          <div class="text-center">
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="
                                                ShowAlert(
                                                  VehicleDetails,
                                                  'Lost'
                                                )
                                              "
                                              style="background-color: #f7ba49"
                                            >
                                              Send to Lost
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- .container -->
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn pending-vendor"
                  v-if="openModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close"
                    @click="(openModel = false), (message = '')"
                  >
                    x
                  </button>
                  <div class="row">
                    <div class="col-md-6">
                      <table class="negTable">
                        <tr>
                          <th><h4>Negotiate </h4></th>
                          <th></th>
                        </tr>
                        <tr>
                          <td>Brand</td>
                          <td class="right-this">
                            <span
                              >{{ auctionEdit.brandName }}
                              {{ auctionEdit.modelName }}
                              {{ auctionEdit.manYear }}</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>VIN</td>
                          <td class="right-this">
                            <span>#{{ auctionEdit.vin }}</span>
                          </td>
                        </tr>
                        <tr
                          v-if="
                            auctionEdit.approveStatus == true &&
                            auctionEdit.CustomerApprovedforSellerId > 0
                          "
                        >
                          <td>Customer accepted your offer amount</td>
                          <td class="right-this">
                            <span>
                              {{
                                $n(
                                  auctionEdit.sellerNegAmount,
                                  "currency",
                                  language
                                )
                              }}</span
                            >
                          </td>
                        </tr>

                        <tr v-if="auctionEdit.approveStatus != true">
                          <td>Bid amount</td>
                          <td class="right-this">
                            <span>
                              {{
                                $n(
                                  auctionEdit.LatestBidAmount,
                                  "currency",
                                  language
                                )
                              }}</span
                            >
                          </td>
                        </tr>
                        <tr
                          v-if="
                            auctionEdit.custNegAmount &&
                            auctionEdit.approveStatus != true
                          "
                        >
                          <td>From customer</td>
                          <td class="right-this">
                            <span>
                              {{
                                $n(
                                  auctionEdit.custNegAmount,
                                  "currency",
                                  language
                                )
                              }}</span
                            >
                          </td>
                        </tr>
                        <tr
                          v-if="
                            auctionEdit.sellerNegAmount &&
                            auctionEdit.approveStatus != true
                          "
                        >
                          <td>Previous offer</td>
                          <td class="right-this">
                            <span>{{
                              $n(
                                auctionEdit.sellerNegAmount,
                                "currency",
                                language
                              )
                            }}</span>
                          </td>
                        </tr>
                        <tr v-if="auctionEdit.approveStatus != true">
                          <td>New offer</td>
                          <td class="right-this">
                            <input
                              style="height: 30px"
                              class="form-control"
                              type="number"
                              v-model="negotiationAmount"
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!-- scroll for negotiation time line -->
                    <div class="col-md-6">
                      <div class="scroll-custome">
                        <div class="rightbox">
                          <h6 class="text-center">Negotiation History</h6>
                          <div
                            class="rb-container"
                            v-if="negotiationTimeLine.length !== 0"
                          >
                            <ul class="rb">
                              <li
                                v-for="negotiationTime in negotiationTimeLine"
                                :key="negotiationTime.negotiateId"
                                class="rb-item"
                                ng-repeat="itembx"
                              >
                                <div class="timestamp">
                                  {{
                                    formatDateTime(
                                      negotiationTime.negotiationDate
                                    )
                                  }}
                                  <!-- 3rd May 2020<br />
                                  7:00 PM -->
                                </div>
                                <span class="p-c-v-name">{{
                                  negotiationTime.userName
                                }}</span>
                                <span class="p-amount">{{
                                  $n(
                                    negotiationTime.negotiationAmount,
                                    "currency",
                                    language
                                  )
                                }}</span>
                              </li>
                            </ul>
                          </div>
                          <div v-else>
                            <img
                              src="/assets/images/no-records.png"
                              class="not-found-imgg"
                              style="width: 40%"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <div class="row">
                    <div class="col-4" v-if="auctionEdit.approveStatus != true">
                      <div>
                        <button
                          v-if="auctionEdit.custNegAmount"
                          class="bid-info-sub"
                          style="background-color: #c90000; !important"
                          @click="rejectOffer(auctionEdit)"
                        >
                          Reject Negotiation
                        </button>
                        <!-- <button
                          v-else
                          class="bid-info-sub"
                          style="background-color: #c90000; !important"
                          @click="cancelNegotiation(auctionEdit)"
                        >
                          Cancel Negotiation
                        </button> -->
                      </div>
                    </div>
                    <div class="col-4" v-if="auctionEdit.approveStatus != true">
                      <div>
                        <button
                          class="bid-info-sub"
                          style="background-color:#648ED1; !important"
                          @click="negotiateOffer(auctionEdit)"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div class="col-4">
                      <div
                        v-if="
                          auctionEdit.approveStatus != true &&
                          auctionEdit.custNegAmount > 0
                        "
                      >
                        <button
                          class="bid-info-sub"
                          style="background-color: #4CAF50; !important"
                          @click="acceptOffer(auctionEdit)"
                        >
                          Accept
                        </button>
                      </div>
                      <div v-if="auctionEdit.approveStatus == true">
                        <button
                          class="bid-info-sub"
                          style="background-color: rgb(32, 122, 32); !important"
                          @click="confirmOffer(auctionEdit)"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
                <!-- Aucction Status Alert Model -->
                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="openShowModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openShowModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning</h4>
                  <p>
                    Are you sure you want to add
                    <span
                      >{{ auctionEdit.brandName }} {{ auctionEdit.modelName }}
                      {{ auctionEdit.manYear }}</span
                    >
                    to {{ modelnameAuc }}?
                    <span>!this action is irreversible</span>
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openShowModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="changeAucStatus(auctionEdit, modelnameAuc)"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="auctionStatusMessage == 'Success'"
                  >
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="auctionStatusMessage">
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="openNegotiationModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openNegotiationModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>Negotiation Alert</h4>
                  <p>
                    Are you sure want to add to
                    <strong>{{ " " + modeNeg }}</strong>
                    {{ "  " + modelnameAuc + " " + negotiationAmount + "  " }}
                    for
                    <span>{{
                      auctionEdit.brandName + "" + auctionEdit.modelName
                    }}</span>
                    ?
                  </p>

                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openNegotiationModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="
                            addNegotiation(auctionEdit, negotiationAmount)
                          "
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
                <!-- cancel negotiation / reject negotiation  /accept negotiation-->
                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="cancelNegotiationModal && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(cancelNegotiationModal = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning Alert</h4>
                  <p>
                    Are you sure want to add to
                    <strong>{{ " " + modeNeg }}</strong>
                    {{ "  " + modelnameAuc + " " + negotiationAmount + "  " }}
                    for
                    <span>{{
                      auctionEdit.brandName + "" + auctionEdit.modelName
                    }}</span>
                    ? ! this action is irreversible .
                  </p>

                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="cancelNegotiationModal = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="updateNegotiationState(auctionEdit, modeNeg)"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>

                <!-- -->

                <!--  -->

                <div
                  class="overlay-bid-btn warningStyle"
                  v-if="openofferConfirmModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_Close"
                    @click="(openofferConfirmModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Sale Confirmation</h4>
                  <p>
                    {{ "  " + modelnameAuc + " " + negotiationAmount + "  " }}
                    for
                    <span>{{
                      auctionEdit.brandName + "" + auctionEdit.modelName
                    }}</span
                    >. ! Are you sure want to confirm sale ? !this action is
                    irreversible
                  </p>

                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openofferConfirmModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="
                            changeAucStatus(auctionEdit, 'Sale Confirmed')
                          "
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import userService from "@/services/user.service";
import Config from "@/config";
import Alert from "../../components/Alert.vue";
import moment from "moment";
import navbar from "@/components/navbar.vue";
export default {
  components: {
    sideMenuVendor,
    PageNation,
    NotFound,
    Alert,
    navbar,
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      openShowModel: false,
      auctionEdit: "",
      message: "",
      modelnameAuc: "",
      negotiationAmount: "",
      openNegotiationModel: false,
      openofferConfirmModel: false,
      customerNegAmount: null,
      auctionStatusMessage: "",
      modeNeg: "",
      negButton: "tile-bid-now",
      negotiationDetails: "",
      language: Config.BASE_LANG,
      negotiationTimeLine: [],
      cancelNegotiationModal: false,
      filteredArray: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.sellerId != "undefined") {
        this.getpendingSellerAuctions(this.userinfo.sellerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.customerNegAmount = 200000;
  },

  watch: {
    filteredArray() {
      // Update items whenever filteredArray changes
      this.updateItems();
    },
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format("MMMM Do YYYY, h:mm");
    },
    async updateNegotiationState(aucInfo, negStatus) {
      if (negStatus == "Canceled") {
        await this.updNegHistory(aucInfo, "Canceled");
        await this.updAuctionStatus(aucInfo, "Lost");
      } else if (negStatus == "Rejected") {
        await this.updNegHistory(aucInfo, "Rejected", 0);
        //await this.updAuctionStatus(aucInfo, "Rejected");
      } else if (negStatus == "Sale Confirmed") {
        await this.updNegHistory(aucInfo, "Sale Confirmed", 1);
        await this.updAuctionStatus(aucInfo, "Sale Confirmed");
      }
    },

    rejectOffer(e) {
      this.cancelNegotiationModal = !this.cancelNegotiationModal;
      this.modelnameAuc = ".Reject customer offer amount";
      this.auctionEdit = e;
      this.modeNeg = "Rejected";
      if (e.custNegAmount != null) {
        this.negotiationAmount = e.custNegAmount;
      } else {
        this.negotiationAmount = e.LatestBidAmount;
      }
      //this.updNegHistory(e, "Rejected");
      // this.updAuctionStatus(e, this.modeNeg);
    },
    cancelNegotiation(e) {
      this.cancelNegotiationModal = !this.cancelNegotiationModal;
      this.modelnameAuc = "  ,the acution will be added to lost,The amount is ";
      this.auctionEdit = e;
      this.modeNeg = "Canceled";
      if (e.custNegAmount != null) {
        this.negotiationAmount = e.custNegAmount;
      } else {
        this.negotiationAmount = e.LatestBidAmount;
      }
      //this.updNegHistory(e, "Canceled");
      //this.updAuctionStatus(e, this.modeNeg);
    },
    negotiateOffer(negotiationDetail) {
      let offerAmount = 0;
      this.message = "";

      if (
        parseInt(
          this.negotiationAmount == null || !this.negotiationAmount
            ? 0
            : this.negotiationAmount
        ) <= 0
      ) {
        this.message = "Plaese enter a valid negotiation amount";
      } else if (
        parseInt(this.negotiationAmount) <= negotiationDetail.LatestBidAmount
      ) {
        this.message = "Negotiation amount should be greater than  bid amount";
      } else {
        offerAmount = parseInt(this.negotiationAmount);
      }

      if (offerAmount > 0) {
        this.message = "";
        this.modeNeg = "Negotiate";
        this.auctionEdit = negotiationDetail;
        this.openNegotiationModel = !this.openNegotiationModel;
        this.modelnameAuc = " change offer amount to: ";
      }
    },
    acceptOffer(e) {
      this.modeNeg = "Sale Confirmed";
      this.cancelNegotiationModal = !this.cancelNegotiationModal;
      this.modelnameAuc = ", accept customer offer amount";
      this.auctionEdit = e;

      if (e.custNegAmount != null) {
        this.negotiationAmount = e.custNegAmount;
      } else {
        this.negotiationAmount = e.LatestBidAmount;
      }
    },
    confirmOffer(e) {
      this.modeNeg = "Sale Confirmed";
      this.openofferConfirmModel = !this.openofferConfirmModel;
      this.modelnameAuc = "customer accepted your offer amount";
      this.auctionEdit = e;

      this.negotiationAmount = e.sellerNegAmount;
    },
    changeAucStatus(aucInfo, aucStatus) {
      this.updAuctionStatus(aucInfo, aucStatus);
    },
    SubmitBid() {
      this.message = "Success";
      var self = this;
      setTimeout(function () {
        self.closeModel();
      }, 2000);
    },
    closeModel() {
      this.message = "";
      this.openModel = false;
    },
    closeopenShowModel() {
      this.auctionStatusMessage = "";
      this.openShowModel = false;
      location.reload();
    },
    reAuction(e) {
  
      
      this.getNegotiationTimeLine(e);
      this.auctionEdit = e;
      this.openModel = !this.openModel;
      this.openShowModel = false;
    },
    ShowAlert(e, r) {
      this.modelnameAuc = r;
      this.auctionEdit = e;
      this.openShowModel = !this.openShowModel;
      this.openNegotiationModel = false;
      this.openModel = false;
    },
    PageRout(userData) {
      this.items = userData;
    },
    updNegHistory(auctionDetails, aucStatus, winStatus) {
    //  console.log(auctionDetails, aucStatus, winStatus);
      userService
        .updNegHistory(auctionDetails, aucStatus, winStatus, "Seller")
        .then((response) => {
          this.auctionStatusMessage = response.data.Message;
          if (this.auctionStatusMessage == "Success") {
            this.$toast.success("Success ", {
              position: "top",
              duration: 3000,
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        })
        .catch((e) => {
          this.$toast.error("!Oops sothing went wrong. try again", {
            position: "top",
            duration: 3000,
          });
          console.log(e);
        });
    },
    updAuctionStatus(negotiationDetail, auctionState) {
      console.log(negotiationDetail, auctionState);
      userService
        .updAuctionStatus(negotiationDetail, auctionState, "Seller")
        .then((response) => {
          this.auctionStatusMessage = response.data.Message;
          if (this.auctionStatusMessage == "Success") {
            this.auctionStatusMessage = "";
            this.$toast.success("Success ", {
              position: "top",
              duration: 3000,
            });
            var self = this;
            setTimeout(function () {
              self.closeopenShowModel();
            }, 2000);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("!Oops sothing went wrong. try again", {
            position: "top",
            duration: 3000,
          });
        });
    },
    async addNegotiation(negotiationDetail, offerAmount) {
      if (this.modeNeg == "Sale Confirmed") {
        await this.updNegHistory(negotiationDetail, this.modeNeg, 1);
        await this.updAuctionStatus(negotiationDetail, this.modeNeg);
      } else if (this.modeNeg == "Canceled") {
        this.updNegHistory(negotiationDetail, this.modeNeg, 0);
      } else if (this.modeNeg == "Negotiate") {
        userService
          .insNegotiationDetails(negotiationDetail, offerAmount, "Seller")
          .then((response) => {
            this.auctionStatusMessage = response.data.Message;
            if (this.auctionStatusMessage == "Success") {
              this.$toast.success("Success ", {
                position: "top",
                duration: 3000,
              });
              var self = this;
              setTimeout(function () {
                self.closeopenShowModel();
              }, 2000);
            }
          })
          .catch((e) => {
            console.log(e);
            this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
            });
          });
      }
    },
    getpendingSellerAuctions(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getSellerAuctionsStatus(id, "Pending")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;

         // console.log( "Pending  List",this.AuctionsDetails);
            //removed rejected auction with single negotiation
            this.filteredArray = this.AuctionsDetails.filter((item) => {
              return !(
                item.firstCustRejectionStatus === null &&
                item.negotiationStatus != null &&
                item.isFirstCustomer !== 1
              );
            });
//
          //  console.log( "Pending filter List",this.filteredArray);

            for (let j = 0; j < this.AuctionsDetails.length; j++) {
              const element = this.AuctionsDetails[j];
              if (
                element.firstCustRejectionStatus === 1 &&
                element.nextCustomer
              ) {
                userService
                  .getSellerAuctionsStatusForSecondCustomer(
                    element.sellerId,
                    "Pending",
                    element.nextCustomer,
                    element.aucid
                  )
                  .then((response) => {
                    let  secondCustomerInfo = response.data.Data;

               //     console.log("Second customer" ,secondCustomerInfo);
                    // Check if VIN already exists in filteredArray
                    const existingIndex = this.filteredArray.findIndex(
                      (item) => item.vin === secondCustomerInfo[0].vin
                    );
                    
                    if (existingIndex !== -1) {
                      // Replace existing object with secondCustomerInfo
                      this.filteredArray.splice(
                        existingIndex,
                        1,
                        secondCustomerInfo[0]
                      );
                    } else {
                      // VIN does not exist, add as a new object
                      this.filteredArray.push(secondCustomerInfo);
                    }

                    console.log("first customer removed " ,this.filteredArray);
                    this.updateItems();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }

         ///   console.log("filtered  final list", this.filteredArray);

            // this.items = filteredArray.slice(
            //   (this.currentPage - 1) * this.perPage,
            //   this.currentPage * this.perPage
            // );

            // Update items based on pagination
            this.updateItems();

            for (let index = 0; index < this.AuctionsDetails.length; index++) {
              const element = this.AuctionsDetails[index];

              this.negotiationDetails = element;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    updateItems() {
      // const start = (this.currentPage - 1) * this.perPage;
      // const end = this.currentPage * this.perPage;
      // this.items = this.filteredArray.slice(start, end);

      this.items = this.filteredArray.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
     // console.log("vehList : ", this.items);
    },

    getNegotiationTimeLine(e) {

      e.aucId = e.aucid;
      if (e.aucid == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getNegotiationTimeLine("vendor", e)
          .then((response) => {
            this.negotiationTimeLine = response.data.Data;
            //console.log(this.negotiationTimeLine);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.overlay-bid-btn {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(255 255 255);
  position: fixed;
  /* width: 50%; */
  margin: 100px 30%;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #00000069;
}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.pending-vendor {
  width: 50%;
}
.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.custom-btn {
  /* width: 130px; */
  /* height: 40px; */
  /* color: #fff; */
  border-radius: 3px;
  padding: 5px 25px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%), 7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%); */
  outline: none;
  margin-top: 6px;
}

/* 11 */
.btn-11 {
  width: 97%;
  border: none;
  /* background: #d0e45f;
    background: linear-gradient(0deg, #648ED1 0%, #648ED1 100%); */
  background: rgb(246 0 88);
  /* background: linear-gradient(
    0deg,
    rgba(251, 33, 117, 1) 0%,
    rgba(234, 76, 137, 1) 100%
  ); */

  color: #fff;
  overflow: hidden;
}
.btn-11:hover {
  text-decoration: none;
  color: #fff;
}
.btn-11:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-11:hover {
  opacity: 0.7;
}
.btn-11:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
.negTable tr td {
  width: 200px;
  padding-top: 8px;
}
td.right-this {
  text-align: right;
}
.not-found-imgg {
  width: 90% !important;
}
</style>
